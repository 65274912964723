<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text" *ngIf="isEditMode === false">
      {{'add_workstation' | translate}}
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt" [routerLink]="'/workstations-list'" [attr.uk-tooltip]="'Wróć'">
        <span uk-icon="sign-out"></span>
      </button>
    </p>
    <p class="nav-text" *ngIf="isEditMode === true">
      {{'workstation' | translate}}: {{workstationCode}}
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt" [routerLink]="'/workstations-list'" [attr.uk-tooltip]="'Wróć'">
        <span uk-icon="sign-out"></span>
      </button>
      <button class="uk-button uk-button-danger padding-horizontal-small button-opt  uk-margin-remove-right" (click)="onRemoveWorkstation()">
        <span uk-icon="trash" [attr.uk-tooltip]="'Usuń stanowisko'"></span>
      </button>
      <button class="uk-button uk-button-danger padding-horizontal-small button-opt  uk-margin-remove-right"  style="background-color: orange" (click)="onClearWorkstation()">
        <span uk-icon="ban" [attr.uk-tooltip]="'Opróżnij stanowisko'"></span>
      </button>
    </p>
  </div>

<div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">

      <div class="uk-width-1-1@m" *ngIf="isEditMode===true">
        <ul class="mobile-overflow uk-accordion" uk-accordion>
          <li class="uk-close">
            <p class="uk-accordion-title uk-heading-divider">Edycja stanowiska...</p>
            <div class="uk-accordion-content">
              <div class="uk-grid">

                <form #workstationForm="ngForm" class="uk-width-1-1" (ngSubmit)="submitWorkstationForm(workstationForm)">
                  <div class="uk-grid">
                    <div class="uk-width-1-2@m">
                      <label class="required">{{'warehouse' | translate}}</label>
                      <select class="uk-select" id="warehouse" name="warehouse" [(ngModel)]="workstationFormData.warehouse.id" required>
                        <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                          {{ warehouse.name }}
                        </option>
                      </select>
                    </div>
                    <div class="uk-width-1-2@m">
                      <label class="required">{{'workstation_type' | translate}}</label>
                      <select class="uk-select"  id="workstation_type" name="workstation_type" [(ngModel)]="workstationFormData.type.id" required>
                        <option *ngFor="let workstation_type of workstation_types" [value]="workstation_type.id">
                          {{ workstation_type.name }} ({{workstation_type.code}})
                        </option>
                      </select>
                    </div>
                    <div class="uk-width-1-2@m">
                      <label class="required">{{'workstation_code' | translate}}</label>
                      <input class="uk-input" type="text" name="workstation_code" [(ngModel)]="workstationFormData.code" required>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <button class="uk-button uk-button-primary" type="submit">{{'save' | translate}}</button>

                    <hr>
                  </div>
                </form>

              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="uk-width-1-1@m" *ngIf="isEditMode===false">
        <form #workstationForm="ngForm" class="uk-width-1-1" (ngSubmit)="submitWorkstationForm(workstationForm)">
          <div class="uk-grid">
            <div class="uk-width-1-2@m">
              <label class="required">{{ 'warehouse' | translate }}</label>
              <select class="uk-select" id="warehouse" name="warehouse" [(ngModel)]="workstationFormData.warehouse.id"
                      required>
                <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                  {{ warehouse.name }}
                </option>
              </select>
            </div>
            <div class="uk-width-1-2@m">
              <label class="required">{{ 'workstation_type' | translate }}</label>
              <select class="uk-select" id="workstation_type" name="workstation_type"
                      [(ngModel)]="workstationFormData.type.id" required>
                <option *ngFor="let workstation_type of workstation_types" [value]="workstation_type.id">
                  {{ workstation_type.name }} [{{workstation_type.code}}]
                </option>
              </select>
            </div>
            <div class="uk-width-1-2@m">
              <label class="required">{{ 'workstation_code' | translate }}</label>
              <input class="uk-input" type="text" name="workstation_code" [(ngModel)]="workstationFormData.code"
                     required>
            </div>
          </div>
          <div class="uk-margin">
            <button *ngIf="isEditMode === false" class="uk-button uk-button-primary"
                    type="submit">{{ 'add' | translate }}
            </button>

          </div>
        </form>
      </div>

      <div class="mobile-overflow" *ngIf="isEditMode===true">

              <table class="uk-table uk-table-divider uk-table-hover uk-width-1-1"  datatable [dtOptions]="dtOptions">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>{{'workstation_code' | translate}}</th>
                  <th>Email</th>
                  <th>Początek</th>
                  <th>Koniec</th>
                  <th>Typ</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let workstationWorkflow of workstationWorkflowData" >
                  <td>{{workstationWorkflow.id}}</td>
                  <td>{{workstationWorkflow.code}}</td>
                  <td>{{workstationWorkflow.email}}</td>
                  <td>{{workstationWorkflow.start_at}}</td>
                  <td>{{workstationWorkflow.end_at}}</td>
                  <td>{{workstationWorkflow.end_type}}</td>
                </tr>
                </tbody>
              </table>

            </div>

        </div>

</div>
</div>

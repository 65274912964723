import { Component, OnInit} from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ToastsService } from '../../../services/toasts.service';
import { ActivatedRoute, Router } from "@angular/router";
import { WorkstationService } from "../../../services/workstation/workstation.service";
import { NgForm } from "@angular/forms";
import {WorkstationModel} from "../../../models/workstation.model";

@Component({
    selector: 'app-workstation',
    templateUrl: './workstation.component.html',
    styleUrls: ['./workstation.component.scss']
})
export class WorkstationComponent implements OnInit {

    constructor(
      private activatedRoute: ActivatedRoute,
      private authService: AuthService,
      private workstationService: WorkstationService,
      private router: Router,
      private toastsService: ToastsService
    )
    {
    }
    dtOptions: DataTables.Settings = {};

    isEditMode = false;
    workstation_id?: string
    workstationCode: string

    workstationFormData: WorkstationModel = {
      id: '',
      code: '',
      type: { id: '' },
      warehouse: { id: '' },
    };

  workstationWorkflowData: any = {
    id: '',
    code: '',
    user_id: '',
    email: '',
    start_at: '',
    end_at: '',
    end_type: ''
  }

    warehouses: any = [];
    workstation_types: any = [];

    userId = '';
    type = 'manual';

    ngOnInit() {

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        processing: true,
        order: [[3, 'DESC']]
      };

      this.activatedRoute.paramMap.subscribe(params => {
        const id = params.get('id');
        if (id) {
          this.isEditMode = true;
          this.workstation_id = id;
          this.loadWorkstationData(id);
        }
      });

      this.workstationService.listWarehouses().subscribe(
        (response) => {
          this.warehouses = response;
        },
        (error) => {
          console.error('Błąd przy pobieraniu magazynów:', error);
        }
      );

      this.workstationService.listTypes().subscribe(
        (response) => {
          this.workstation_types = response;
        },
        (error) => {
          console.error('Błąd przy pobieraniu typów stanowisk:', error);
        }
      );
    }

  submitWorkstationForm(workstationForm: NgForm) {
    if (workstationForm.valid) {

      if(this.isEditMode) {
        this.updateWorkstation()
      } else {
        this.workstationService.store(this.workstationFormData).subscribe({
          next: (response) => {
            void this.router.navigate(['/workstations-list']);
            this.toastsService.showMessage('Pomyślnie dodano nowe stanowisko', 's');
          },
          error: (err) => {
            console.error('Błąd podczas zapisu:', err.status);
            if(err.status === 422) {
              this.toastsService.showMessage('Błąd podaczas dodawania stanowiska', 'e', err);
            }
          }
        });
      }
    }
  }

  loadWorkstationData(workstationId)
  {
    this.workstationService.getWorkstation(workstationId).subscribe(
      (workstationData: WorkstationModel) => {
        this.workstationFormData = this.mapWorkstationModel(workstationData)
        this.workstationCode = workstationData.code
      }
    )

    this.getWorkstationWorkflow(workstationId);
  }

  mapWorkstationModel(data: any): WorkstationModel {
      return {
        id: data.id,
        code: data.code,
        type: { id: data.workstation_type_id },
        warehouse: { id: data.warehouse_id }
      }
  }

  getWorkstationWorkflow(workstationId)
  {
    this.workstationService.getWorkstationWorkflow(workstationId).subscribe(
      (workstationWorkflowData: any) => {
        this.workstationWorkflowData = workstationWorkflowData.data
      }
    )
  }

  async onRemoveWorkstation() {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.workstationService.deleteWorkstation(this.workstation_id).subscribe(
      (response) => {
        if(response === true) {
          this.toastsService.showMessage('Pomyślnie usunięto stanowisko', 's');
          void this.router.navigate(['/workstations-list']);
        }
      },
      (error) => {
        console.error('Error deleting workstation', error);
      }
    );
  }

  updateWorkstation() {
      this.workstationService.updateWorkstation(this.workstationFormData).subscribe(
        (response: any) => {
          this.toastsService.showMessage('Pomyślnie edytowano stanowisko', 's');
        }
      )
  }

  async onClearWorkstation() {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    if(this.workstationWorkflowData.length === 0) {
      this.toastsService.showMessage('Stanowisko jest aktualnie wolne', 'e');
    }

    if(this.workstationWorkflowData[0].end_at === null) {
      this.userId = this.workstationWorkflowData[0].user_id;

      this.workstationService.endWorkflowByAdmin(this.userId, this.type).subscribe(
        (response: any) => {
          this.toastsService.showMessage('Pomyślnie opróżniono stanowisko', 's');
        }
      )
    }
  }
}

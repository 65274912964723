import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastsService} from "../../../services/toasts.service";
import {ParcelService} from "../../../services/parcel/parcel.service";

declare var UIkit: any;

@Component({
  selector: 'set-new-buffer-number-modal',
  templateUrl: './set-new-buffer-number-modal.component.html',
  styleUrls: ['./set-new-buffer-number-modal.component.scss']
})
export class SetNewBufferNumberModalComponent implements OnInit {
  @Input('parcelId') parcelId = null;
  @Output('correctlySubmittedEvent') correctlySubmittedEvent = new EventEmitter();

  form = {
    number: null,
    emag: 0,
  }

  constructor(
    public toastService: ToastsService,
    public parcelService: ParcelService
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.parcelService.setNewBufferNumber(this.parcelId, this.form.number, this.form.emag).subscribe(
      (response: any) => {
        this.toastService.showMessage('Pomyślnie ustawiono nowy numer przesyłki!', 's');
        UIkit.modal('#set-new-buffer-number-modal').hide();
        this.correctlySubmittedEvent.emit();
      },
      (error: any) => {
      },
    );
  }
}

import { Component, OnInit} from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import {ActivatedRoute} from "@angular/router";
import {WorkstationService} from "../../../services/workstation/workstation.service";
import {UserRole} from "../../../enums/user-role";
import {NgForm} from "@angular/forms";
import {WorkstationWorkflowModel} from "../../../models/workstation-workflow.model";

@Component({
    selector: 'app-workstation-workflow-history',
    templateUrl: './workstation-workflow-history.component.html',
    styleUrls: ['./workstation-workflow-history.component.scss']
})
export class WorkstationWorkflowHistoryComponent implements OnInit {

    constructor(
      private activatedRoute: ActivatedRoute,
      private workstationService: WorkstationService,
      private authService: AuthService,
    )
    {
    }
    dtOptions: DataTables.Settings = {};

  workstation_types: any = [];
    employees: any = [];

    workstations: any [];

    private roles = [UserRole.admin, UserRole.warehouse_worker];

    workstationWorkflowFormData: WorkstationWorkflowModel = {
      workstationId: null,
      typeId: null,
      inUse: null,
      userId: null,
    };

    workflowData: any = {

    }

    ngOnInit() {

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        processing: true,
        order: [[3, 'DESC']]
      };

      this.workstationService.listTypes().subscribe(
        (response) => {
          this.workstation_types = response;
        },
        (error) => {
          console.error('Błąd przy pobieraniu typów stanowisk:', error);
        }
      );

      this.workstationService.listEmployees(this.roles).subscribe(
        (response) => {
          this.employees = response;
        },
        (error) => {
          console.error('Błąd przy pobieraniu pracowników:', error);
        }
      )
    }

  submitWorkstationWorkflowForm(workflowForm: NgForm) {
    if (workflowForm.valid) {
      this.workstationService.getWorkflow(this.workstationWorkflowFormData).subscribe(
        (response: any) => {
          this.workflowData = response.data
        }
      )

    }
  }
}

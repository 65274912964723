import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProblemsService} from "../../../services/problems/problems.service";
import {ParcelsService} from "../../../services/parcels/parcels.service";
import {ToastsService} from "../../../services/toasts.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {WorkstationService} from "../../../services/workstation/workstation.service";
import {ProblematicSectorService} from "../../../services/problematic-sector/problematic-sector.service";
import {NgForm} from "@angular/forms";
@Component({
  selector: 'app-problematic-sector',
  templateUrl: './problematic-sector.component.html',
  styleUrls: ['./problematic-sector.component.scss']
})
export class ProblematicSectorComponent implements OnInit {
  id = null;
  problematic_sector = {
    warehouse_id: null,
    code: null,
    name: null,
    internal: null,
    status_type_id: null
  };

  constructor(
    private problemsService: ProblemsService,
    private problematicSectorService: ProblematicSectorService,
    private workstationService: WorkstationService,
    private route: ActivatedRoute,
    private router: Router,
    private parcelsServices: ParcelsService,
    private toastsService: ToastsService
  ) {
  }

  dtOptions: DataTables.Settings = {};

  warehouses: any = [];

  ngOnInit(): void {
    try {
      const id = this.route.snapshot.paramMap.get('id');
      if (id != undefined)
        this.id = id;
    } catch (error) {
      this.id = null;
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };

    this.workstationService.listWarehouses().subscribe(
      (response) => {
        this.warehouses = response;
      },
      (error) => {
        console.error('Błąd przy pobieraniu magazynów:', error);
      }
    );
  }

  update(){
    this.problemsService.updateRule(this.problematic_sector).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Zmodyfikowano pomyślnie!', 's');
      }
    );
  }

  submitProblematicSectorForm(form: NgForm) {
    this.problematicSectorService.createProblematicSector(this.problematic_sector).subscribe({
      next: (response) => {
        this.toastsService.showMessage('Utworzono pomyślnie!', 's');
        void this.router.navigate(['/problematic-sector/list']);
      },
      error: (err) => {
        if (err.status === 422) {
          this.toastsService.showMessage('Błąd podczas dodawania typu', 'e');
        }
      }
    });
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService {
  private apiurl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.apiurl = environment.api + 'app/v1/warehouse';
  }

  list() {
    return this.http.get(this.apiurl);
  }
}

import {WorkstationModel} from "./workstation.model";

export class UserModel {
    id: string;
    id_client: string;
    email: string;
    role: number;
    _token: string;
    deleted_at: string;
    api_enabled: boolean;
    iai_idosell_enabled: boolean;
    workstation: WorkstationModel|null;
}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'parcel_reported_resignation' | translate}}
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt" [attr.uk-tooltip]="'Wróć'" [routerLink]="'/parcel-reported-resignations'"><span uk-icon="sign-out"></span></button>
            <button class="uk-button uk-button-danger padding-horizontal-small button-opt uk-margin-remove-right" *ngIf="null !== id" [attr.uk-tooltip]="'Usuń'" (click)="delete()"><span uk-icon="ban"></span></button>
        </p>
    </div>

    <div class="uk-width-1-1 inner-shadow">

        <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
              <div class="uk-grid">
                <div class="uk-width-1-2@m" *ngIf="null !== id">
                  <label  class="required">{{'client' | translate}}</label>
                  <input class="uk-input" disabled type="text" [(ngModel)]="model.client_name">
                </div>
                <div class="uk-width-1-2@m" *ngIf="null === id">
                  <label  class="required">{{'client' | translate}}</label>
                  <input autocomplete="off" [class.uk-form-danger]="clientInput.touched && id_client.value == ''" (keyup)="searchClient(clientInput)" type="text" #clientInput="ngModel"[(ngModel)]="model.client_name" class="uk-input" required #clientField>

                  <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                    <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="clients.length > 0" [style.width]="clientField.clientWidth + 'px'">
                      <li *ngFor="let client of clients" (click)="setClient(client);">{{client.name}}</li>
                    </ul>
                  </div>

                  <input type="hidden" #id_client="ngModel" autocomplete="none" name="id_client" [(ngModel)]="model.client_id"  class="uk-input">
                </div>
                <div class="uk-width-1-2@m">
                  <label class="required">Numer przesyłki</label>
                  <input class="uk-input" type="text" [(ngModel)]="model.waybill">
                </div>
                <div class="uk-width-1-1@m">
                  <label>Dodatkowe informacje</label>
                  <textarea class="uk-input" [(ngModel)]="model.note"></textarea>
                </div>
                <div class="uk-width-1-2@m" *ngIf="null !== id">
                  <label>Zeskanowano dnia</label>
                  <input class="uk-input" type="datetime" disabled [(ngModel)]="model.scanned_at">
                </div>
                <div class="uk-width-1-2@m" *ngIf="null !== id">
                  <label>Zeskanowano przez</label>
                  <input class="uk-input" type="text" disabled [(ngModel)]="model.scanned_by_user_name">
                </div>
                <div class="uk-width-1-2@m" *ngIf="null !== id">
                  <label>Odesłano dnia</label>
                  <input class="uk-input" type="datetime-local" [(ngModel)]="model.resend_at">
                </div>
                <div class="uk-width-3-4@m uk-margin-bottom uk-margin-top">
                  <button class="uk-button uk-button-primary" (click)="save()">Zapisz</button>
                </div>
              </div>
          </div>

        </div>

    </div>

</div>

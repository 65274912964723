<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      <span>Tworzenie nowego typu problemu</span>
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt " [attr.uk-tooltip]="'Wróć'" [routerLink]="'/problematic-sector/list'"><span uk-icon="sign-out"></span></button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">

      <div class="mobile-overflow">
          <div class="uk-grid uk-margin-bottom">
            <form #problematicSectorForm="ngForm" class="uk-flex uk-flex-row uk-flex-wrap uk-width-1-1">
            <div class="uk-width-1-3@m uk-margin-right">
              <label class="required">Nazwa typu problemu</label>
              <input type="text" class="uk-input" [(ngModel)]="problematic_sector.name" name="name">
            </div>
              <div class="uk-width-1-5@m uk-margin-right">
                <label class="required">{{'warehouse' | translate}}</label>
                <select class="uk-select" id="warehouse" name="warehouse" [(ngModel)]="problematic_sector.warehouse_id">
                  <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                    {{ warehouse.name }}
                  </option>
                </select>
              </div>
            <div class="uk-width-1-6@m uk-margin-right">
              <label class="required">Wewnętrzny</label>
              <select class="uk-select" name="id_next_status" [(ngModel)]="problematic_sector.internal">
                <option [value]="0">Nie</option>
                <option [value]="1">Tak</option>
              </select>
            </div>
              <div class="uk-width-1-6@m uk-margin-right">
                <label class="required">Kod</label>
                <input type="text" class="uk-input" [(ngModel)]="problematic_sector.code" name="code">
              </div>
            </form>

            <div class="uk-margin">
              <button class="uk-button uk-button-primary" (click)="submitProblematicSectorForm(problematicSectorForm)">{{'save' | translate}}</button>
            </div>
          </div>

      </div>

    </div>

  </div>

</div>


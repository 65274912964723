import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {saveAs} from 'file-saver';
import {ToastsService} from "../toasts.service";

@Injectable({
  providedIn: 'root'
})
export class ParcelsService {
  private apiurl = '';

  constructor(private http: HttpClient, private router: Router, private toastsService: ToastsService) {
    this.apiurl = environment.api;
  }

  createParcel(form) {
    const url = this.apiurl + 'parcel';
    return this.http.post(url, form);
  }

  checkParcel(form) {
    const url = this.apiurl + 'parcel/check';
    return this.http.post(url, form);
  }

  getParcel(id) {
    const url = this.apiurl + 'parcel/data/' + id;
    return this.http.get(url);
  }

  getFile(url, filename): void {
    this.http.get(url, {responseType: 'blob'})
      .subscribe((res: any) => {
        const blob = new Blob([res], {type: res.type.toString()});
        const objUrl = window.URL.createObjectURL(blob);
        saveAs(blob, filename);
      }, (error) => {
        if (error.status === 404) {
          this.toastsService.showMessage('not_found_file', 'e');
        } else if (error.status === 415) {
          this.toastsService.showMessage('wait_for_label', 'e');
        }
      });
  }

  downloadExtParcels(){
    const url = this.apiurl + 'parcels/ext/download';
    return this.http.get(url);
  }

  getStatusTypes(id=0) {
    const url = this.apiurl + 'statuses/select/' + id;
    return this.http.get(url);
  }

  listWorkstationTypeAvailableStatusTypes(workstationTypeId) {
    return this.http.get(
      this.apiurl + 'app/v1/status_type/sorted_for_warehouse_select/' + workstationTypeId,
    );
  }

  findStatusTypesForWarehouseSelect() {
    return this.http.get(
      this.apiurl + 'app/v1/status_type/sorted_for_warehouse_select'
    );
  }

  getParcelStatuses(internal) {
    const url = this.apiurl + 'parcel/' + internal;
    return this.http.get(url);
  }

  getPublicParcelStatusHistory(internal) {
    const url = this.apiurl + 'tracking/' + internal;
    return this.http.get(url, {observe: 'response'});
  }

  getPublicParcelOutNumber(out) {
    const url = this.apiurl + 'tracking/external/' + out;
    return this.http.get(url);
  }

  updateParcel(form) {
    const url = this.apiurl + 'parcel';
    return this.http.put(url, form);
  }

  trackingManager(form) {
    const url = this.apiurl + 'tracking-manager';
    return this.http.put(url, form);
  }

  makeParcelRequest(form) {
    const url = this.apiurl + 'parcel/make';
    return this.http.post(url, form);
  }

  getCity(query, courier = 'DPD') {
    const url = this.apiurl + `${courier}/location/city`;
    return this.http.post(url, {query});
  }

  getStreet(query, city_code, courier = 'DPD') {
    const url = this.apiurl + `${courier}/location/street`;
    return this.http.post(url, {city_code: city_code, searchable: query});
  }

  removeParcel(id) {
    const url = this.apiurl + 'parcel';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(url, options);
  }

  cancelParcel(id, comment) {
    const url = this.apiurl + 'parcel/cancel';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id, comment
      },
    };
    return this.http.delete(url, options);
  }

  setStatus(form) {
    const url = this.apiurl + 'parcel/status';
    return this.http.put(url, form);
  }

  setGroupStatus(form) {
    const url = this.apiurl + 'parcel/status/group';
    return this.http.put(url, form);
  }

  deleteStatus(id) {
    const url = this.apiurl + 'parcel/status';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id,
      },
    };
    return this.http.delete(url, options);
  }

  getPublicFileToken(){
    const url = this.apiurl + 'public_token';
    return this.http.get(url);
  }

  checkParcelPrice(form){
    const url = this.apiurl + 'parcel/price';
    return this.http.post(url, form);
  }

  askShowPrice(){
    const url = this.apiurl + 'ask/price';
    return this.http.get(url);
  }

  getProblemId(idParcel) {
    const url = this.apiurl + 'parcel/problem/' + idParcel;
    return this.http.get(url);
  }

  getRandomParcelData() {
    const url = this.apiurl + 'ask/random/parcel';
    return this.http.get(url);
  }

  copy(internalNumber) {
    const url = this.apiurl + 'app/v1/parcel/' + internalNumber + '/copy';

    return this.http.get(url);
  }
}

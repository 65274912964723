import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {WorkstationService} from "../../../services/workstation/workstation.service";
import {WorkstationModel} from "../../../models/workstation.model";
import {NgForm} from "@angular/forms";
import {ToastsService} from "../../../services/toasts.service";

@Component({
    selector: 'app-workstations_list',
    templateUrl: './workstations-list.component.html',
    styleUrls: ['./workstations-list.component.scss']
})
export class WorkstationsListComponent implements OnInit {

    constructor(
      private activatedRoute: ActivatedRoute,
      private workstationService: WorkstationService,
      private toastsService: ToastsService,
    )
    {
    }
  dtOptions: DataTables.Settings = {};

  warehouses: any = [];
  workstation_types: any = [];

  card = 'workstations-list';

  filterWorkstationData: WorkstationModel = {
    code: '',
    type: { id: '' },
    warehouse: { id: '' },
  };

  filteredWorkstations: any =[];
    ngOnInit() {

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        processing: true,
      };

      try {
        const card = this.activatedRoute.snapshot.paramMap.get('card');
        if (card !== undefined && card !== null) {
          this.card = card;
        }
      }
      catch (e) {
        this.card = 'workstations-list';
      }

      this.workstationService.listWarehouses().subscribe(
        (response) => {
          this.warehouses = response;
        },
        (error) => {
          console.error('Błąd przy pobieraniu magazynów:', error);
        }
      );

      this.workstationService.listTypes().subscribe(
        (response) => {
          this.workstation_types = response;
        },
        (error) => {
          console.error('Błąd przy pobieraniu:', error);
        }
      );
    }

  filterWorkstation(filterWorkstationForm: NgForm) {
      if(filterWorkstationForm.valid) {
        this.workstationService.listFullInfoWorkstations(
          this.filterWorkstationData.warehouse.id,
          this.filterWorkstationData.type.id
        ).subscribe({
          next: (response: any) => {
            this.filteredWorkstations = response.data;
            if(this.filteredWorkstations.length !== 0) {
              this.toastsService.showMessage('Pomyślnie przefiltrowano stanowiska', 's');
            } else {
              this.toastsService.showMessage('Nie znalzeiono stanowisk', 'e');
            }
          },
          error: (err) => {
            console.error('Błąd podczas pobierania danych:', err);
          }
        });
      }
    }

}

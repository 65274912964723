import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {ClassificationService} from '../../../services/classification/classification.service';
import {StatusesService} from "../../../services/statuses/statuses.service";
import {ProblematicSectorService} from "../../../services/problematic-sector/problematic-sector.service";
import {AuthService} from "../../../services/auth/auth.service";
import {UserModel} from "../../../models/user.model";

declare var UIkit: any;

@Component({
  selector: 'app-parcel-classification',
  templateUrl: './parcel-classification.component.html',
  styleUrls: ['./parcel-classification.component.scss']
})
export class ParcelClassificationComponent implements OnInit, OnDestroy {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  internalNumber = null;

  apiurl = null;
  id = null;
  statuses = [];
  error = false;

  idClient = null;
  client = null;

  scanningLocked = false;

  public lastChars = '';
  public lastKeyCode = null;
  public clearLastCharsInterval = null;
  statusTypes = [];
  barcode = null;
  statusTypeId = null;
  problematic_sector_id = null;
  statusSystemId = null;
  problematicSectors = [];
  unknownProblemSector = null;

  constructor(
    private router: Router,
      private parcelsService: ParcelsService,
      private authService: AuthService,
      private toastsService: ToastsService,
      private classificationService: ClassificationService,
      private statusService: StatusesService,
      private problematicSectorService: ProblematicSectorService
  ) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
    this.clearLastChars();
    this.setWorkstation();
  }

  runCourierClassification() {
    this.classificationService.classifyByCourier({
      barcode: this.barcode,
      status_type_id: this.statusTypeId,
      problematic_sector_id: this.problematic_sector_id,
    }).subscribe(
      (response: any) => {
        console.log(response.body);
        switch (response.status) {
          case 201:
            this.toastsService.showMessage('Pomyślnie ustawiono status i kod błędu', 's');
          break;
          case 205:
            this.setWorkstation();
            this.toastsService.showMessage('Pomyślnie ustawiono stanowisko!', 's');
            break;
        }

        if (response.body.completed_multi_pack_waybill) {
          this.toastsService.showMultiPackIsCompletedMessage(
            response.body.completed_multi_pack_waybill,
            15000
          );
        }
        this.toastsService.showSortingMessage(response.body.bucket.name, response.body.bucket.identifier, 1500);
        this.resetProblematic();
      },
      (error: any) => {
        switch (error.status) {
          case 402:
            this.toastsService.showWrongClientAliasParcel(3000);
            break;
          case 403:
            this.toastsService.showMessage('Jesteś zalogowany na innym stanowisku!','e');
            break;
          case 405:
            this.toastsService.showBlockDispatchMessage(5000);
            break;
          case 406:
            this.toastsService.showSortingMessage(false, null, 1500);
            break;
          case 410:
            this.toastsService.showDeletedParcelMessage(5000);
            break;
          case 412:
            this.toastsService.showBigErrorMessage(
              'Ta przesyłka jest częścią wielopaku który oczekuje jeszcze w buforze',
              'Poinformuj biuro obłsugi klienta',
              3000,
            );
            break;
          case 424:
            this.toastsService.showNotFullyScannedMultiPackMessage(3000);
            break;
          case 425:
            this.toastsService.showTooEarlyScannedMessage(3000);
            break;
          default:
            this.toastsService.showSortingMessage(null, null, 1500);
            break;
        }

        this.checkProblematic(error.error);
      }
    );
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  eanChecker(tempChars) {
    if (tempChars.length === 8) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN8', 'w', 5000);
      return false;
    }
    if (tempChars.length === 13) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN13', 'w', 5000);
      return false;
    }
    if (tempChars.length === 14) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN14', 'w', 5000);
      return false;
    }
    if (tempChars.length > 28 && !tempChars.includes('re_internal_instruction')) {
      this.toastsService.showMessage('Zbyt szybko skanujesz przesyłki', 'w', 5000);
      return false;
    }

    return true;
  }

  listenerAction = (e) => {
    this.clearLastChars();
    if ((e.ctrlKey || e.metaKey)) {
      return;
    }

    this.lastKeyCode = e.keyCode;

    if ((e.keyCode >= 48 && e.keyCode <= 57 && this.lastKeyCode !== 16)
      || e.keyCode === 189 || (e.keyCode > 40 && e.keyCode < 91)) {
      if (e.keyCode === 189) {
        this.lastChars += '_';
      } else {
        this.lastChars += String.fromCharCode(e.keyCode) + '';
      }
    }

    this.lastKeyCode = e.keyCode;
  }

  checkNumbers() {
    if (this.lastChars.length === 0 || !this.eanChecker(this.lastChars)){
      return;
    }

    this.barcode = this.lastChars;
    this.runCourierClassification();
  }

  clearLastChars() {
    if (this.clearLastCharsInterval != null) {
      clearInterval(this.clearLastCharsInterval);
      this.clearLastCharsInterval = null;
    }
    this.clearLastCharsInterval = setInterval(
      () => {
        this.checkNumbers();
        this.lastChars = '';
      }, 400
    );
  }

  closeProblematicSectorChooseModal() {
    UIkit.modal('#problematicSectorChooseModal').hide();
  }

  getStatusTypes(workstationTypeId): void {
    this.parcelsService.listWorkstationTypeAvailableStatusTypes(workstationTypeId).subscribe(
      (response: any) => {
        this.statusTypes = response;
      }
    );
  }

  getProblematicSectors(warehouseId, workstationTypeId): void {
    this.problematicSectorService.list(warehouseId, workstationTypeId).subscribe(
      (sectors: any)=> {
        this.statusService.getStatusTypeBySystemId('unknown_problem_detected').subscribe(
          (statusType: any) => {
            for (let sector of sectors) {
              if (statusType.id === sector.status_type_id) {
                this.unknownProblemSector = sector;
              }
              else {
                this.problematicSectors.push(sector);
              }
            }
          }
        );
      }
    )
  }

  workstationTypeId = null;

  setWorkstation(): void {
    this.authService.whoami().subscribe((userModel: UserModel) => {
      if (null === userModel.workstation) {
        this.router.navigateByUrl('/set-workstation');
        this.toastsService.showMessage('Przed rozpoczęciem pracy musisz zeskanować stanowisko!', 'e');
        return;
      }

      this.workstationTypeId = userModel.workstation.type.id;
      this.getStatusTypes(userModel.workstation.type.id);
      this.getProblematicSectors(userModel.workstation.warehouse.id, userModel.workstation.type.id);
    });
  }

  statusChanged(): void {
    this.problematic_sector_id = null;
    this.statusSystemId = null;
    for (let statusType of this.statusTypes) {
      if (statusType.id == this.statusTypeId) {
        this.statusSystemId = statusType.system_id;
      }
    }

    if ('known_problem_detected' === this.statusSystemId) {
      UIkit.modal('#problematicSectorChooseModal').show();
    }

    if ('unknown_problem_detected' === this.statusSystemId) {

      this.problematic_sector_id = this.unknownProblemSector.id;
      const promised: Promise<boolean> = this.toastsService.showConfirmModal();

      promised.then(
        (): void => {
          this.runCourierClassification();
        }
      );
    }
  }

  problematicStatusNeeded = false;

  checkProblematic(body) {
    console.log(body)
    if (null === body.data.suggested_status_type_id
      || undefined === body.data.suggested_status_type_id
    ) {

      return;
    }

    this.problematicStatusNeeded = true;

    this.statusTypeId = body.data.suggested_status_type_id;
    if (null !== body.data.suggested_problematic_sector_id) {
      UIkit.modal('#problematicSectorChooseModal').show();
      this.problematic_sector_id = body.data.suggested_problematic_sector_id;
    }
    this.findStatusSystemId();
    this.toastsService.showMessage('Wykryto nieprawidłowość, rozważ przekazania przesyłki do odpowiendiego sektora!', 'w', 1000);
  }

  findStatusSystemId() {
    for (let statusType of this.statusTypes) {
      if (statusType.id == this.statusTypeId) {
        this.statusSystemId = statusType.system_id;
      }
    }
  }

  resetProblematic() {
    this.statusTypeId = null
    this.getStatusTypes(this.workstationTypeId);
    this.problematic_sector_id = null;
    this.closeProblematicSectorChooseModal();
  }
}


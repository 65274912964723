
<script src="../../../services/problems/problems.service.ts"></script>
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      Sortuj przesyłki
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-width-1-1@l" (click)="scanningLocked = false">
        <hr>
        <form class="" #parcelForm="ngForm" (ngSubmit)="runCourierClassification()">
          <div class="uk-alert uk-alert-danger" *ngIf="error">
            {{"parcel_search_error" | translate}}
          </div>

          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label>{{'parcel_number' | translate}}</label>
                <input name="number" class="uk-input" type="text" required [(ngModel)]="barcode" #barcodeInput>
              </div>
            </div>
            <div class="uk-width-1-3@m">
              <label>{{'choose_status' | translate}}</label>
              <select name="status_type_id"
                      required
                      class="uk-input"
                      [(ngModel)]="statusTypeId"
                      (change)="statusChanged()"
              >
                <option>Brak</option>
                <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">{{statusType.displayed_name}}
                </option>
              </select>
            </div>
            <div class="uk-width-1-3@m" *ngIf="'known_problem_detected' === this.statusSystemId">
              <label>{{'Wbierz regułę problemu' | translate}}</label>
              <select name="problematic_sector_id" required class="uk-input" [(ngModel)]="problematic_sector_id">
                <option *ngFor="let sector of problematicSectors" value="{{sector.id}}">[{{sector.code}}] {{sector.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="uk-margin-top">
            <button [disabled]="!parcelForm.valid" type="submit" class="uk-button uk-button-primary">
              Sortuj
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div id="problematicSectorChooseModal" class="problematicSectorChooseModal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close (click)="closeProblematicSectorChooseModal()"></button>
    <div class=" uk-modal-body">
      <div class="uk-grid" >
        <div class="uk-width-1-1">
          <label>{{'Wbierz problem' | translate}}</label>
          <select name="problematic_sector_id" required class="uk-input" [(ngModel)]="problematic_sector_id">
            <option *ngFor="let sector of problematicSectors" value="{{sector.id}}">[{{sector.code}}] {{sector.name}}
            </option>
          </select>

        </div>
        <div class="uk-width-1-2 mt-23">
          <button class="uk-button uk-button-primary" type="button" (click)="runCourierClassification(); resetProblematic();">OK</button>
        </div>
        <div class="uk-width-1-2 mt-23 ">
          <button class="uk-button uk-button-danger"
                  type="button" (click)="resetProblematic()">
            Rezygnuję
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


import {Component, Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {StatusModel} from '../../../models/status.model';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {ScanningService} from '../../../services/scanning/scanning.service';
import {ParcelService} from '../../../services/parcel/parcel.service';
import {KeyCode} from "../../../enums/key-code";
import {ClientsService} from "../../../services/clients/clients.service";
import {
  InternalInstructionCodeService
} from "../../../services/internal-instruction-code/internal-instruction-code.service";
import {UserModel} from "../../../models/user.model";
import {StatusesService} from "../../../services/statuses/statuses.service";
import {ProblematicSectorService} from "../../../services/problematic-sector/problematic-sector.service";
import {AuthService} from "../../../services/auth/auth.service";

declare var UIkit: any;

@Component({
  selector: 'app-parcel-from-number-and-assign-to-client',
  templateUrl: './parcel-from-number-and-assign-to-client.component.html',
  styleUrls: ['./parcel-from-number-and-assign-to-client.component.scss']
})
export class ParcelFromNumberAndAssignToClientComponent implements OnInit, OnDestroy {
  private DUPLICATED_PROBLEMATIC_SECTOR_CODES = [3,4];
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  @ViewChild('chosenClientNameInput') chosenClientNameInput: ElementRef;
  @ViewChild('postalCodeInput') postalCodeInput: ElementRef;
  @ViewChild('weightInput') weightInput: ElementRef;

  activeSection: string = 'barcodeForm';
  findParcelDataColumns: string[] = [
    'weight', 'weight_value', 'height', 'width', 'length', 'volumetric_weight', 'out_number', 'internal_number',
  ];

  apiurl: string = null;
  parcelStatusHistory: any[] = [];

  parcelModel = null;
  operatedParcelId: number = null;
  workstationTypeId = null;


  statusTypes: any[] = [];
  clientName: string = '(kliknij aby wybrać)';

  chooseClientList: any[] = [];

  chosenClientId: null|number = null;
  chosenClientName: string = null;
  clientChosen: boolean = false;
  scannedWaybill = null;
  externalInboundBarcode: string = null;
  problematicSectors = [];
  unknownProblemSector = null;
  statusTypeId = 55;

  form = {
    client_id: null,
    barcode: null,
    status_type_id: 55,
    when: '',
    where: 'TYCHY',
    external_inbound_id: null,
    problematic_sector_id: null,
    create_duplicated_parcel_row: null,
  };

  barcodeInputFocusLocked = false;

  dimensionsForm = {
    parcel_id: null,
    weight: null,
    width: null,
    length: null,
    height: null,
  };

  constructor(
    private authService: AuthService,
    private clientsService: ClientsService,
    private parcelService: ParcelService,
    private parcelsService: ParcelsService,
    private toastsService: ToastsService,
    private scanningService: ScanningService,
    private internalInstructionCodeService: InternalInstructionCodeService,
    private statusService: StatusesService,
    private problematicSectorService: ProblematicSectorService
  ) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
   // this.clearLastChars();
    this.setWorkstation();
    this.setTime();
    this.setFocusOnBarcodeInputBackgroundTask();
    setTimeout(
      () => {
        this.setFocusOnBarcodeInput();
      }, 0
    );
  }

  confirmClientForm = {
      postal_code: null,
      phone_number: null,
      client_id: null,
  };

  setTime() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    this.form.when = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  }

  scan() {
    console.log(this.form);

    this.form.status_type_id = this.statusTypeId;
    this.resetUpdateDimensionsForm();
    this.scanningService.scanAndAssignToClient(this.form).subscribe(
      (response: any) => {
        switch (response.status) {
          case 201:
            let showShortSuccessMessage = true;
            if (showShortSuccessMessage) {
              this.toastsService.showBigSuccessMessage();
            }

            this.findParcelData(response.body.data.parcel_id, false, response.body.data.re_pasting_required);
            this.findParcelStatusesHistory(response.body.data.parcel_id);
            break;

          case 202:
            this.resetForm();
            this.form.client_id = response.body.client.id;
            this.clientName = response.body.client.name;
            this.externalInboundBarcode = null;
            this.toastsService.showBigSuccessMessage('Pomyślnie ustawiono kienta!');
            break;
          case 203:
            this.resetForm();
            this.form.external_inbound_id = response.body.external_inbound.id;
            this.externalInboundBarcode = response.body.external_inbound.barcode;
            this.clientName = null;
            this.toastsService.showBigSuccessMessage();
            this.showChangeClientModal();
            break;
          case 205:
            this.setWorkstation();
            this.toastsService.showMessage('Pomyślnie ustawiono stanowisko!', 's');

            break;
          case 206:
            for (const message of response.body.messages) {
              this.toastsService.showMessage(message.text, message.type, 15000);
            }
            this.resetProblematic();
            this.resetForm();
            break;
          case 208:
            console.log('should checking problematic')
            this.operatedParcelId = response.body.data.parcel_id;
            this.scannedWaybill = response.body.data.waybill;
            for (const message of response.body.messages) {
              this.toastsService.showMessage(message.text, message.type, 15000);
            }

            this.checkProblematic(response.body);

            this.findParcelData(response.body.data.parcel_id, false, response.body.data.re_pasting_required);
            this.findParcelStatusesHistory(response.body.data.parcel_id);
            return;

          default:

            break;
        }

        this.form.barcode = '';
      },
      (error: any) => {
        switch (error.status) {
          case 403:
            this.toastsService.showMessage('Jesteś zalogowany na innym stanowisku!','e');
            break;
          case 422:
            this.toastsService.showMessage(error.error.message, 'e', 5000);
            break;
        }

        this.form.barcode = '';
      }
    );
    this.setTime();
  }

  problematicStatusNeeded: boolean = false;

  checkProblematic(body): boolean {
    if (
      undefined === body.data
      || null === body.data.suggested_status_type_id
      || undefined === body.data.suggested_status_type_id
    ) {
      return false;
    }

    this.problematicStatusNeeded = true;

    this.form.status_type_id = body.data.suggested_status_type_id;
    this.statusTypeId = body.data.suggested_status_type_id;
    if (null !== body.data.suggested_problematic_sector_id) {
      UIkit.modal('#problematicSectorChooseModal').show();
      this.form.problematic_sector_id = body.data.suggested_problematic_sector_id;
    }

    this.findStatusSystemId();
    this.lockAutoFocusOnBarcodeForAWhile();
    this.checkIsDuplicatedParcelProblematicSector();
    this.toastsService.showMessage('Wykryto nieprawidłowość, rozważ przekazania przesyłki do odpowiendiego sektora!', 'w', 1000);

    return true;
  }

  findStatusSystemId(): void {
    for (let statusType of this.statusTypes) {
      if (statusType.id == this.form.status_type_id) {
        this.statusSystemId = statusType.system_id;
      }
    }
  }

  resetForm() {
    console.log('resettiing');
    this.form = {
      client_id: null,
      barcode: null,
      status_type_id: this.statusTypeId,
      when: '',
      where: 'TYCHY',
      external_inbound_id: null,
      problematic_sector_id: null,
      create_duplicated_parcel_row: false,
    };

    this.externalInboundBarcode = null;
    this.clientName = '(kliknij aby wybrać)';
    this.setTime();
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  setFocusOnBarcodeInputBackgroundTask() {
    setTimeout(
      () => {
        if (!this.barcodeInputFocusLocked) {
          this.setFocusOnBarcodeInput();
        }
        this.setFocusOnBarcodeInputBackgroundTask();
      },
      2000
    );
  }

  unlockAutoFocusOnBarcode() {
    this.barcodeInputFocusLocked = false;
  }

  setFocusOnBarcodeInput() {
    this.barcodeInput.nativeElement.focus();
    this.activeSection = 'barcodeForm';
  }

  lockAutoFocusOnBarcodeForAWhile() {
    this.barcodeInputFocusLocked = true;
  }

  listenerAction = (e: KeyboardEvent) => {
    if (KeyCode.ESCAPE === e.keyCode) {
      if (this.barcodeInputFocusLocked) {
        this.setFocusOnBarcodeInput();
      }

      this.barcodeInputFocusLocked = !this.barcodeInputFocusLocked;
    }
    if (KeyCode.TAB ===e.keyCode) {
      this.barcodeInputFocusLocked = true;
    }

    if (KeyCode.DELETE === e.keyCode) {
      switch (this.activeSection) {
        case 'barcodeForm':
          this.form.barcode = '';
          break;
        case 'clientChooseForm':
          this.chosenClientName = '';
          this.chosenClientId = null;
          this.clientChosen = false;
          break;
        case 'updateDimensionsForm':
          this.resetUpdateDimensionsForm();
          break;
      }
    }

    if (KeyCode.PAGE_DOWN === e.keyCode) {
      this.lockAutoFocusOnBarcodeForAWhile();
      this.activeSection = 'updateDimensionsForm';
      this.weightInput.nativeElement.focus();
    }

    if (this.barcodeInputFocusLocked) {
      return;
    }

    return;
  }

  findParcelStatusesHistory(parcelId: number) {
    this.parcelService.findByHistoryIdentifier(parcelId).subscribe(
      (response: any) => {
        this.parcelStatusHistory = response.sort((a, b) => {
          return (a.when < b.when) ? 1 : ((a.when > b.when) ? -1 : 0);
        })
      }
    );
  }

  findParcelData(parcelId: number, clearInfo = false, openRePasting = false) {
    this.parcelService.findByIdentifier(parcelId, this.findParcelDataColumns).subscribe(
      (response: any) => {
        this.parcelModel = response;

        if (openRePasting) {
          if ('-' == this.parcelModel.out_number) {
              this.toastsService.showMessage('Przesyłka nie posiada numeru wyjściowego', 'e', 20000)
          }
          else {
            this.openLabelToPrint(this.parcelModel.internal_number);
          }
        }

        if (clearInfo) {
          console.log('cleaning')
          setTimeout(
            () => {
              this.form.barcode = '';
              this.parcelModel = null;
              this.parcelStatusHistory = [];
              this.operatedParcelId = null;
            }, 1500
          );
        }
      }
    );
  }

  openLabelToPrint(outNumber) {
    this.parcelsService.getPublicFileToken().subscribe(
      (response: any) => {
        window.open(this.apiurl + 'public/label/' + outNumber + '?_public_token=' + response.public_token);
      }
    );
  }

  /* change client */
  showChangeClientModal() {
    this.barcodeInputFocusLocked = true;
    this.activeSection = 'clientChooseForm';
    UIkit.modal('#chooseClientModal').show();
    setTimeout(
      () => {
        this.chosenClientNameInput.nativeElement.focus();
      },
      10,
    );
  }

  closeChangeClientModal() {
    this.toastsService.showMessage('Pomyślnie wybrano klienta!', 's');
    this.unlockAutoFocusOnBarcode();
    UIkit.modal('#chooseClientModal').hide();
  }

  chooseClientEnter() {
    const decodedInstructionCode = this.internalInstructionCodeService.decode(this.chosenClientName);
    if (null !== decodedInstructionCode && undefined!== decodedInstructionCode.client) {
      this.clientName = decodedInstructionCode.client.name;
      this.form.client_id = decodedInstructionCode.client.id;
      this.closeChangeClientModal();
    }
  }

  clientChars = [];
  searchClientsToChoose(input){
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.chooseClientList = response;
      }
    );
  }

  setChosenClient(client: any) {
    this.chosenClientId = client.id;
    this.chosenClientName = client.name;
    this.chooseClientList = [];
    this.clientChosen = true;
    setTimeout(
      () => {
        this.postalCodeInput.nativeElement.focus();
      }, 5
    );
  }

  verifyClientChoose() {
    this.confirmClientForm.client_id = this.chosenClientId;
    this.clientsService.verifyChoose(this.confirmClientForm).subscribe(
      (response: any) => {
        this.form.client_id = this.chosenClientId;
        this.clientName = this.chosenClientName;
        this.closeChangeClientModal();
      },
      (error: any) => {
        this.toastsService.showMessage('Niepoprawna weryfikacja wyboru klienta!', 'e');
      }
    );
  }

  /* dimensions division */
  resetUpdateDimensionsForm() {
    this.dimensionsForm = {
      parcel_id: null,
      weight: null,
      width: null,
      length: null,
      height: null,
    };
  }

  async showConfirmDimensionsModal(form, message){
    return await this.toastsService.showConfirmDimensionsModal(form, message);
  }

  updateDimensions() {
    const form = {
      id: this.operatedParcelId,
      weight: this.dimensionsForm.weight,
      length: this.dimensionsForm.length,
      height: this.dimensionsForm.height,
      width: this.dimensionsForm.width,
    };

    this.parcelService.checkDimensions(form).subscribe(
      (checkResponse: any) => {
        this.updateDimensionsRequest(form);
      },
      e => {
        if (e.status === 406) {
          this.showConfirmDimensionsModal(form, e.error[0]).then(confirmed => {
              if (confirmed) {
                this.updateDimensionsRequest(form);
              } else {
                this.toastsService.showMessage('Zmiany nie zostały zapisane', 'w', 3000)
              }
            },
          );
        }
        if (e.status === 422) {
          this.toastsService.showMessage(e.error.message, 'e');
        }
      }
    );
  }

  updateDimensionsRequest(form) {
    this.parcelService.updateSelectiveDimensions(form).subscribe(
      (response: any) => {
        this.resetUpdateDimensionsForm();
        this.toastsService.showMessage('Wymiary przesyłki zostały zaktualizowane!', 's');
        this.findParcelData(this.operatedParcelId, true);
        this.barcodeInput.nativeElement.focus();
        this.unlockAutoFocusOnBarcode();
      },
      e => {
        this.toastsService.showMessage(
          e.status === 422
            ? e.error.message
            : 'Wystąpił błąd podczas aktualizacji wymiarów',
          'e'
        );
      }
    );
  }

  getStatusTypes(workstationTypeId): void {
    this.parcelsService.listWorkstationTypeAvailableStatusTypes(workstationTypeId).subscribe(
      (response: any) => {
        this.statusTypes = response;
        if (response.length > 0) {
          this.form.status_type_id = response[0].id;
        }
        this.findStatusSystemId();
      }
    );
  }

  getProblematicSectors(warehouseId, workstationTypeId): void {
    this.problematicSectorService.list(warehouseId, workstationTypeId).subscribe(
      (sectors: any)=> {
        this.statusService.getStatusTypeBySystemId('unknown_problem_detected').subscribe(
          (statusType: any) => {
            for (let sector of sectors) {
              if (statusType.id === sector.status_type_id) {
                this.unknownProblemSector = sector;
              }
              else {
                this.problematicSectors.push(sector);
              }
            }
          }
        );
      }
    )
  }

  setWorkstation(): void {
    this.authService.whoami().subscribe((userModel: UserModel) => {
      if (null === userModel.workstation) {
        this.toastsService.showMessage('Przed rozpoczęciem pracy musisz zeskanować stanowisko!', 'e');
        return;
      }

      this.workstationTypeId = userModel.workstation.type.id;
      this.getStatusTypes(userModel.workstation.type.id);
      this.getProblematicSectors(userModel.workstation.warehouse.id, userModel.workstation.type.id);
    });
  }

  statusSystemId = null;

  statusChanged(): void {
    this.form.problematic_sector_id = null;
    this.statusSystemId = null;
    for (let statusType of this.statusTypes) {
      if (statusType.id == this.statusTypeId) {
        this.statusSystemId = statusType.system_id;
      }
    }

    if ('known_problem_detected' === this.statusSystemId) {
      UIkit.modal('#problematicSectorChooseModal').show();
    }

    if ('unknown_problem_detected' === this.statusSystemId) {
      this.form.problematic_sector_id = this.unknownProblemSector.id;
    }

    this.checkIsDuplicatedParcelProblematicSector();
  }

  closeProblematicSectorChooseModal() {
    UIkit.modal('#problematicSectorChooseModal').hide();
  }

  isDuplicatedProblematicSector: boolean = false;

  checkIsDuplicatedParcelProblematicSector(): void {
    this.isDuplicatedProblematicSector = false;
    this.form.create_duplicated_parcel_row = false;
    for (let sector of this.problematicSectors) {
      if (sector.id == this.form.problematic_sector_id) {
        for (let code of this.DUPLICATED_PROBLEMATIC_SECTOR_CODES) {
          if (code == sector.code) {
            this.isDuplicatedProblematicSector = true;
            this.form.create_duplicated_parcel_row = true;
          }
        }
      }
    }
  }

  resetProblematic(): void {
    this.form.problematic_sector_id = null;
    this.form.create_duplicated_parcel_row = null;
    this.getStatusTypes(this.workstationTypeId);
    this.closeProblematicSectorChooseModal();
    this.isDuplicatedProblematicSector = false;
    this.findStatusSystemId();
  }
}

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      Ustaw stanowisko
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-width-1-1@l">
        <form class="" #parcelForm="ngForm" (ngSubmit)="scan()">
          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label>{{'Kod stanowiska' | translate}}</label>
                <input name="number"
                       class="uk-input"
                       type="text"
                       required
                       [(ngModel)]="this.form.code"
                       #barcodeInput
                >
              </div>
            </div>
          </div>
          <div class="uk-margin-top">
            <button type="submit" class="uk-button uk-button-primary">
              Ustaw
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'workstations' | translate}}
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt">
        <span uk-icon="plus"  [attr.uk-tooltip]="'Dodaj stanowisko'"  routerLink="/workstation/new"></span>
      </button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">

      <div class="uk-grid uk-margin-bottom">
        <div class="uk-width-1-3@m">
          <button class="uk-button uk-button-primary uk-width-1-1"
                  [class.uk-button-primary]="card === 'workstations-list'" (click)="card='workstations-list'">
            <span>{{'workstations_list' | translate}} </span>
          </button>
        </div>
        <div class="uk-width-1-3@m">
          <button class="uk-button uk-button-primary uk-width-1-1"
                  [class.uk-button-primary]="card === 'workflow_history'" (click)="card='workflow_history'">
            <span>{{'workflow_history' | translate}} </span>
          </button>
        </div>
      </div>

<div class="mobile-overflow" *ngIf="card === 'workstations-list'">
  <form #filterWorkstationForm="ngForm" (ngSubmit)="filterWorkstation(filterWorkstationForm)">
    <div class="uk-grid">
      <div class="uk-width-1-4@m">
        <label>{{'warehouse' | translate}}</label>
        <select class="uk-select" id="warehouse" name="warehouse" [(ngModel)]="filterWorkstationData.warehouse.id">
          <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
            {{ warehouse.name }}
          </option>
        </select>
      </div>
      <div class="uk-width-1-4@m">
        <label>{{'workstation_type' | translate}}</label>
        <select class="uk-select"  id="workstation_type" name="workstation_type" [(ngModel)]="filterWorkstationData.type.id">
          <option selected *ngFor="let workstation_type of workstation_types" [value]="workstation_type.id">
            {{ workstation_type.code }}
          </option>
        </select>
      </div>
    </div>
    <div class="uk-margin">
      <button class="uk-button uk-button-primary" type="submit">{{'filter' | translate}}</button>
    </div>
  </form>

  <hr>

  <div *ngIf="filteredWorkstations.length > 0">

    <table class="uk-table uk-table-divider uk-table-hover uk-width-1-1" datatable [dtOptions]="dtOptions">
      <thead>
      <tr>
        <th>ID</th>
        <th>{{'workstation_code' | translate}}</th>
        <th>{{'status' | translate }} </th>
        <th>Pracownik</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let workstation of filteredWorkstations" [routerLink]=" '/workstation/'+ workstation.id">
        <td>{{ workstation.id }}</td>
        <td>{{ workstation.code }}</td>
          <td [ngStyle]="{'color': workstation.active_user_id === null ? 'green' : 'red', 'font-weight': workstation.active_user_id === null ? 'normal' : 'bold'}">
              {{ workstation.active_user_id === null ? 'Wolne' : 'Zajęte' }}
          </td>

        <td *ngIf="workstation.active_user_id !== null">{{ workstation.email }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

      <app-workstation-workflow-history *ngIf="card === 'workflow_history'">

      </app-workstation-workflow-history>

      <app-workstation *ngIf="card === 'workstation'">

      </app-workstation>

    </div>
  </div>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth/auth.service';
import {UserModel} from '../../../models/user.model';
import {ClientsService} from '../../../services/clients/clients.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {UserRole} from "../../../enums/user-role";
import {TagsService} from "../../../services/tags/tags.service";
import {WarehouseService} from "../../../services/warehouse/warehouse.service";
import {WorkstationService} from "../../../services/workstation/workstation.service";
import {ProblematicSectorService} from "../../../services/problematic-sector/problematic-sector.service";

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  clients = [];
  apiUrl = null;
  dtTranslation = null;

  items = [];
  public dtInit = false;
  statusModel = {
    ids: [],
    id_status_type: null,
    when: null,
    where: 'TYCHY',
  };

  public userModel: UserModel = new UserModel();
  dtOptions: DataTables.Settings = {};
  timeout = 100;
  subscriptions = [];
  problematicSectors = [];
  workstations = [];
  warehouses = [];
  warehouseId = null;
  workstationId = null;
  workstationTypeId = null;
  form = {
    type: 'client',
    label: null,
    value: null,
  }


  workstationTypes = [];

  constructor(
    private authService: AuthService,
    private clientsService: ClientsService,
    private tagsService: TagsService,
    private warehouseService: WarehouseService,
    private workstationService: WorkstationService,
    private problematicSectorsService: ProblematicSectorService,
  ) {
    this.apiUrl = environment.api;
  }

  ngOnInit() {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });

    this.warehouseService.list().subscribe(
      (warehouses: []) => {
        this.warehouses = warehouses;
      }
    );

    this.workstationService.listTypes().subscribe(
      (types: []) => {
        this.workstationTypes = types;
      }
    );
  }

  searchClient(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.clients = response;
      }
    );
  }

  searchWorkstation(): void {
    if (null === this.workstationTypeId || null === this.warehouseId) {
      return;
    }

    this.workstationService.listWorkstations(this.warehouseId, this.workstationTypeId).subscribe(
      (workstations: any) => {
        this.workstations = workstations;
      }
    )
  }

  searchProblematicSector(): void {
    if (null === this.warehouseId) {
      return;
    }

    this.problematicSectorsService.list(this.warehouseId).subscribe(
      (sectors: []) => {
        this.problematicSectors =sectors;
      }
    )
  }

  setClient(client): void {
    this.clients = [];
    this.form.value = client.id;
    this.form.label = client.name;
  }


  orderTag(): void {
    this.tagsService.order(this.form).subscribe(
      (response: Blob) => {
        var url = window.URL.createObjectURL(response);
        var anchor = document.createElement("a");
        anchor.download = this.form.type + ' ' + this.form.value + ' tag.pdf';
        anchor.href = url;
        anchor.click();

        this.warehouseId = null;
        this.workstationTypeId = null;
        this.form = {
          type: this.form.type,
          label: null,
          value: null,
        }
      }
    );
  }

  protected readonly UserRole = UserRole;
  protected readonly Object = Object;
}

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      Generuj tag
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-width-1-1@l" >
        <hr>
        <form>
          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label>{{'Rodzaj' | translate}}</label>
                <select class="uk-select" name="type" [(ngModel)]="form.type">
                  <option value="client">Klient</option>
                  <option value="workstation">Stanowisko robocze</option>
                  <option value="problematic_sector">Sektor przesyłek problematycznych</option>
                </select>
              </div>
            </div>

            <div class="uk-width-1-3" *ngIf="'client' === form.type">
              <label>Klient</label>
              <input autocomplete="off" [class.uk-form-danger]="clientInput.touched && id_client.value == ''" (keyup)="searchClient(clientInput)" type="text" #clientInput="ngModel" name="client" [(ngModel)]="form.label" class="uk-input" #clientField>
              <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="clients.length > 0" [style.width]="clientField.clientWidth + 'px'">
                  <li *ngFor="let client of clients" (click)="setClient(client);" style="padding-left: 5px!important;">{{client.name}}</li>
                </ul>
              </div>
              <input type="hidden" #id_client="ngModel" autocomplete="none" [(ngModel)]="form.value" name="value" class="uk-input">
            </div>

            <div class="uk-width-1-3" *ngIf="'workstation' === form.type || 'problematic_sector' === form.type">
              <label>Magazyn</label>
              <select [(ngModel)]="warehouseId" name="warehouseId" class="uk-select" (change)="searchWorkstation(); searchProblematicSector();">
                <option *ngFor="let warehouse of warehouses" value="{{warehouse.id}}">{{warehouse.name}}</option>
              </select>
            </div>

            <div class="uk-width-1-3" *ngIf="'workstation' === form.type">
              <label>Rodzaj</label>
              <select [(ngModel)]="workstationTypeId" name="warehouseTypeId" class="uk-select" (change)="searchWorkstation()">
                <option *ngFor="let type of workstationTypes" value="{{type.id}}">{{type.name}} [{{type.code}}]</option>
              </select>
            </div>

            <div class="uk-width-1-3" *ngIf="'workstation' === form.type">
              <label>Stanowisko</label>
              <select [(ngModel)]="form.value" class="uk-select" name="value">
                <option *ngFor="let workstation of workstations" value="{{workstation.id}}">{{workstation.code}}</option>
              </select>
            </div>

            <div class="uk-width-1-3" *ngIf="'problematic_sector' === form.type">
              <label>Sektor przesyłek problematycznych</label>
              <select [(ngModel)]="form.value" name="value" class="uk-select">
                <option *ngFor="let problematicSector of problematicSectors" value="{{problematicSector.id}}">{{problematicSector.name}} [{{problematicSector.code}}]</option>
              </select>
            </div>

          </div>

          <div class="uk-grid">
            <div class="uk-width-1-3">
              <button class="uk-button uk-button-primary uk-width-1-1" (click)="orderTag()">
                Generuj
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


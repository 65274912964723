import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProblematicSectorService {
  private apiurl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.apiurl = environment.api + 'app/v1/problematic-sector/';
  }

  list(warehouseId = null, warehouseTypeId = null) {
    let url = this.apiurl + 'list';

    if (null !== warehouseId) {
      url += '?warehouse_id=' + warehouseId;
      if (null !== warehouseTypeId) {
        url += '&workstation_type_id=' + warehouseTypeId;
      }
    }

    return this.http.get(url);
  }

  resolve(form) {
    return this.http.put(
      this.apiurl + 'resolve',
      form,
      {observe: 'response'}
    );
  }

  createProblematicSector(form) {
    return this.http.post(this.apiurl, form);
  }

  deleteProblematicSector(problemTypeId) {
    return this.http.delete(this.apiurl, {body: {id: problemTypeId}});
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/pages/login/login.component';
import {UsersComponent} from './components/pages/users/users.component';
import {BundlesComponent} from './components/pages/bundles/bundles.component';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {appRoutes} from '../routes';
import {SidebarAdminComponent} from './components/blocks/sidebar-admin/sidebar-admin.component';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import {DataTablesModule} from 'angular-datatables';
import {AuthGuardGuard} from './guards/auth-guard.guard';
import {TokenInterceptor} from './services/token.interceptor';
import { UserComponent } from './components/pages/user/user.component';
import { ClientsComponent } from './components/pages/clients/clients.component';
import { ClientComponent } from './components/pages/client/client.component';
import {AuthorizationInterceptor} from './services/authorization.interceptor';
import { ToastrModule } from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { UserNewComponent } from './components/pages/user-new/user-new.component';
import { ClientNewComponent } from './components/pages/client-new/client-new.component';
import { ParcelComponent } from './components/pages/parcel/parcel.component';
import { ParcelNewComponent } from './components/pages/parcel-new/parcel-new.component';
import { ParcelPublicComponent } from './components/pages/parcel-public/parcel-public.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { PasswordRecoverComponent } from './components/pages/password-recover/password-recover.component';
import { PricesComponent } from './components/pages/prices/prices.component';
import { StatusesComponent } from './components/pages/statuses/statuses.component';
import { StatusComponent } from './components/pages/status/status.component';
import { PriceComponent } from './components/pages/price/price.component';
import { MailCheckComponent } from './components/pages/mail-check/mail-check.component';
import { ClientCompanyDataComponent } from './components/pages/client-company-data/client-company-data.component';
import { ApiSettingsComponent } from './components/pages/api-settings/api-settings.component';
import { TrackingManagerComponent } from './components/pages/tracking-manager/tracking-manager.component';
import { ProblemRulesComponent } from './components/pages/problem-rules/problem-rules.component';
import { ProblemRuleComponent } from './components/pages/problem-rule/problem-rule.component';
import { ProblemsComponent } from './components/pages/problems/problems.component';
import { ProblemComponent } from './components/pages/problem/problem.component';
import { DaysComponent } from './components/pages/days/days.component';
import {IaiSettingsComponent} from './components/pages/iai-settings/iai-settings.component';
import {CheckPermissionsModalComponent} from './components/modals/check-permissions-modal/check-permissions-modal.component';
import {StatsComponent} from './components/pages/stats/stats.component';
import {ClientDataComponent} from './components/pages/client/client-data/client-data.component';
import {ClientCredentialsComponent} from './components/pages/client/client-credentails/client-credentials.component';
import {ClientCredentailComponent} from './components/pages/client/client-credentail/client-credentail.component';
import {ClientPricesComponent} from './components/pages/client/client-prices/client-prices.component';
import {ClientPriceComponent} from './components/pages/client/client-price/client-price.component';
import {StatusGroupsListComponent} from './components/pages/status-groups/list/status-groups-list.component';
import {StatusGroupsFormComponent} from './components/pages/status-groups/form/status-groups-form.component';
import {ParcelFromNumberComponent} from './components/pages/parcel-from-number/parcel-from-number.component';
import {
  ClientAdditionalParametersComponent
} from './components/pages/client/client-additional-parameters/client-additional-parameters.component';
import {
  ClientAdditionalParameterComponent
} from './components/pages/client/client-additional-parameter/client-additional-parameter.component';
import {
  OperationalPriceListComponent
} from './components/pages/operational-price-list/operational-price-list.component';
import {
  OperationalPriceListFormComponent
} from './components/pages/operational-price-list-form/operational-price-list-form.component';
import {
  ClientOperationalPriceListComponent
} from './components/pages/client/client-operational-price-list/client-operational-price-list.component';
import {
  ParcelReportedResignationsListComponent
} from './components/pages/parcel_reported_resignations/parcel-reported-resignations-list/parcel-reported-resignations-list.component';
import {
  ParcelReportedResignationComponent
} from './components/pages/parcel_reported_resignations/parcel-reported-resignation/parcel-reported-resignation.component';
import {ParcelsListComponent} from './components/pages/parcels-list/parcels-list.component';
import {ParcelClassificationComponent} from './components/pages/parcel-classification/parcel-classification.component';
import {ParcelScanSearchComponent} from './components/pages/parcel-scan-search/parcel-scan-search.component';
import {ExportListComponent} from './components/modals/export-list/export-list.component';
import {SidebarClientComponent} from './components/blocks/sidebar-client/sidebar-client.component';
import {
  SidebarWarehouseWorkerComponent
} from './components/blocks/sidebar-warehouse-worker/sidebar-warehouse-worker.component';
import {AbstractSidebarComponent} from './components/blocks/abstract-sidebar/abstract-sidebar.component';
import {
  UnknownClientAliasesComponent
} from "./components/pages/unknown-client-aliases/unknown-client-aliases.component";
import {UnknownClientAliasComponent} from "./components/pages/unknown-client-alias/unknown-client-alias.component";
import {
  MyParcelReportedResignationComponent
} from "./components/pages/client-role/my-parcel-reported-resignations/my-parcel-reported-resignation/my-parcel-reported-resignation.component";
import {
  MyParcelReportedResignationsListComponent
} from "./components/pages/client-role/my-parcel-reported-resignations/my-parcel-reported-resignations-list/my-parcel-reported-resignations-list.component";
import { ClientExternalApiCredentialsComponent } from './components/pages/client/client-external-api-credentials/client-external-api-credentials.component';
import { ClientNewExternalApiCredentialsComponent } from './components/pages/client-new-external-api-credentials/client-new-external-api-credentials.component';
import { ClientExternalApiCredentialsDetialsComponent } from './components/pages/client/client-external-api-credentials-detials/client-external-api-credentials-detials.component';
import {FindPostalCodeComponent} from "./components/pages/public/find-postal-code/find-postal-code.component";
import {InvoicesComponent} from "./components/pages/invoice/invoices/invoices.component";
import {InvoiceComponent} from "./components/pages/invoice/invoice/invoice.component";
import {ClientInfoModalComponent} from "./components/modals/client-info-modal/client-info-modal.component";
import {TagComponent} from "./components/pages/tag/tag.component";
import {ScanExternalInboundComponent} from "./components/pages/scan-external-inbound/scan-external-inbound.component";
import {ExternalInboundListComponent} from "./components/pages/external-inbound-list/external-inbound-list.component";
import {ExternalInboundOneComponent} from "./components/pages/external-inbound-one/external-inbound-one.component";
import {
  ParcelFromNumberAndAssignToClientComponent
} from "./components/pages/parcel-from-number-and-assign-to-client/parcel-from-number-and-assign-to-client.component";
import {DownloadsComponent} from "./components/pages/downloads/downloads.component";
import {WorkstationComponent} from "./components/pages/workstation/workstation.component";
import {WorkstationsListComponent} from "./components/pages/workstations-list/workstations-list.component";
import {
  WorkstationWorkflowHistoryComponent
} from "./components/pages/workstation-workflow-history/workstation-workflow-history.component";
import {SetWorkstationComponent} from "./components/pages/set-workstation/set-workstation.component";
import {ResolveProblematicComponent} from "./components/pages/resolve-problematic/resolve-problematic.component";
import {
  SetNewBufferNumberModalComponent
} from "./components/modals/set-new-out-number-modal/set-new-buffer-number-modal.component";
import {ProblematicSectorComponent} from "./components/pages/problematic-sector/problematic-sector.component";
import {ProblematicSectorListComponent} from "./components/pages/problematic-sector-list/problematic-sector-list.component";

@NgModule({
    declarations: [
      //client-role
      MyParcelReportedResignationComponent,
      MyParcelReportedResignationsListComponent,

      //rest
        UnknownClientAliasComponent,
        UnknownClientAliasesComponent,
        ExportListComponent,
        ParcelScanSearchComponent,
        ParcelsListComponent,
        IaiSettingsComponent,
        AppComponent,
        LoginComponent,
        UsersComponent,
        BundlesComponent,
        DashboardComponent,
        UserComponent,
        ClientsComponent,
        ClientComponent,
        UserNewComponent,
        ClientNewComponent,
        ParcelComponent,
        ParcelNewComponent,
        ParcelPublicComponent,
        ProfileComponent,
        PasswordRecoverComponent,
        PricesComponent,
        StatusesComponent,
        StatusComponent,
        PriceComponent,
        MailCheckComponent,
        ClientCompanyDataComponent,
        ApiSettingsComponent,
        TrackingManagerComponent,
        ProblemRulesComponent,
        ProblemRuleComponent,
        ProblemsComponent,
        ProblemComponent,
        DaysComponent,
        CheckPermissionsModalComponent,
        ClientInfoModalComponent,
        StatsComponent,
        ClientCredentialsComponent,
        ClientCredentailComponent,
        ClientPricesComponent,
        ClientPriceComponent,
        ClientDataComponent,
        ClientAdditionalParametersComponent,
        ClientAdditionalParameterComponent,
        // new
        StatusGroupsListComponent,
        StatusGroupsFormComponent,
        ParcelFromNumberComponent,
        OperationalPriceListComponent,
        OperationalPriceListFormComponent,
        ClientOperationalPriceListComponent,
        ParcelReportedResignationComponent,
        ParcelReportedResignationsListComponent,
        ParcelClassificationComponent,
        AbstractSidebarComponent,
        SidebarAdminComponent,
        SidebarClientComponent,
        SidebarWarehouseWorkerComponent,
        ClientExternalApiCredentialsComponent,
        ClientNewExternalApiCredentialsComponent,
        ClientExternalApiCredentialsDetialsComponent,
        FindPostalCodeComponent,
        InvoicesComponent,
        InvoiceComponent,
        TagComponent,
        ScanExternalInboundComponent,
        ExternalInboundListComponent,
        ExternalInboundOneComponent,
        ParcelFromNumberAndAssignToClientComponent,
        DownloadsComponent,
        WorkstationComponent,
        WorkstationsListComponent,
        WorkstationWorkflowHistoryComponent,

        SetWorkstationComponent,
        ResolveProblematicComponent,
        SetNewBufferNumberModalComponent,
        ProblematicSectorComponent,
        ProblematicSectorListComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        DataTablesModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-left',
            preventDuplicates: true,
            enableHtml: true,
        }),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        HttpClient,
        AuthGuardGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    public locale = navigator.language;
    public languages = ['pl'];

    constructor(private translate: TranslateService) {
        this.locale = this.locale.slice(0, 2);
        const lastLang = localStorage.getItem('language');
        if (lastLang !== null && lastLang !== undefined) {
            this.locale = lastLang;
        }

        if (this.languages.includes(this.locale)) {
            translate.setDefaultLang(this.locale);
        } else {
            translate.setDefaultLang('pl');
        }
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

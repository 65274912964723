<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      <span>Typy problemów</span>
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt">
        <span uk-icon="plus"  [attr.uk-tooltip]="'Dodaj typ problemu'"  routerLink="/problematic-sector/new"></span>
      </button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">


    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">

      <div class="mobile-overflow">
        <form #problematicSectorForm="ngForm" class="uk-width-1-1" (ngSubmit)="submitProblematicSectorForm(problematicSectorForm)">
        <div class="uk-width-1-4@m uk-margin-small-top">


            <label>{{ 'warehouse' | translate }}</label>
            <select class="uk-select" id="warehouse" name="warehouse" [(ngModel)]="problematicSectorFormData.warehouseId">
              <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                {{ warehouse.name }}
              </option>
            </select>
            <div class="uk-margin">
              <button class="uk-button uk-button-primary" type="submit">{{'filter' | translate}}</button>
            </div>
        </div>
        </form>

        <hr>

        <table class="uk-table uk-table-divider uk-table-hover uk-width-1-1"   datatable [dtOptions]="dtOptions">
              <thead>
              <tr>
                <th>ID</th>
                <th>Kod</th>
                <th>{{'name' | translate }} </th>
                <th>Wewnętrzny</th>
                <th>Akcja</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let problematicSector of problematicSectors">
                <td>{{ problematicSector.id }}</td>
                <td>{{ problematicSector.code }}</td>
                <td>{{ problematicSector.name }}</td>
                <td>{{ problematicSector.internal }}</td>
                <td>
                    <button class="uk-button uk-button-danger padding-horizontal-small" (click)="delete(problematicSector.id)" [attr.uk-tooltip]="'Usuń typ problemu'"><span uk-icon="trash"></span></button>
                </td>
              </tr>
              </tbody>
            </table>
      </div>

    </div>
  </div>

</div>


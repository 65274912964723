<div id="set-new-buffer-number-modal" class="set-new-buffer-number-modal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'Nadaj nowy numer wyjściowy' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
        <div class="uk-grid">
          <div class="uk-width-1-1@m">
            <label class="required">{{'Nowy numer przesyłki' | translate}}</label>
            <input class="uk-input" type="text" [(ngModel)]="form.number" required>
          </div>
          <div class="uk-width-2-5@m">
            <label class="required">{{'Przesyłka emag' | translate}}</label>
            <select class="uk-select" [(ngModel)]="form.emag" name="emag">
              <option value="0">Ne</option>
              <option value="1">Tak</option>
            </select>
          </div>
        </div>
        <div class="uk-margin">
          <button class="uk-button uk-button-primary" (click)="submit()">{{'check' | translate}}</button>
        </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class TagsService {
  private apiurl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.apiurl = environment.api + 'app/v1/tag';
  }


  order(form) {
      return this.http.post(this.apiurl, form, { responseType: 'blob' });
  }

  scan(code) {
    return this.http.patch(environment.api + 'app/v1/warehouse/scan/internal_instruction_code', {
      code,
    });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {WorkstationModel} from "../../models/workstation.model";
import {Observable} from "rxjs";
import {UserRole} from "../../enums/user-role";

@Injectable({
    providedIn: 'root'
})
export class WorkstationService {
  private apiurl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.apiurl = environment.api + 'app/v1/warehouse/';
  }

  store(form: WorkstationModel): Observable<WorkstationModel> {
    return this.http.post<WorkstationModel>(this.apiurl + 'workstation/store', form)
  }

  listTypes() {
    return this.http.get(this.apiurl+'workstation-types');
  }

  listWarehouses() {
    return this.http.get(this.apiurl);
  }

  listWorkstations(warehouseId, typeId?) {
    return this.http.get(this.apiurl + warehouseId + '/workstation/by-type/' + typeId);
  }

  listFullInfoWorkstations(warehouseId, typeId?) {
    return this.http.get(this.apiurl + warehouseId + '/workstation/full-info/' + typeId);
  }

  getWorkstation(workstationId) {
    return this.http.get(this.apiurl + 'workstation/' + workstationId)
  }

  getWorkstationWorkflow(workstationId) {
    return this.http.get(this.apiurl+ 'workstation/workflow/' + workstationId)
  }

  getWorkflow(form) {
    return this.http.post(this.apiurl+ 'workstation/workflow/', form)
  }

  listEmployees(roles: UserRole[]): Observable<string[]> {
    const body = { roles: roles.map(role => role.valueOf()) };
    console.log(body)
    return this.http.post<string[]>(`${ environment.api }users/employees-emails`, body);
  }

  deleteWorkstation(workstationId) {
    return this.http.delete(this.apiurl+ 'workstation/', {body: {workstation_id: workstationId}})
  }

  updateWorkstation(form: WorkstationModel): Observable<WorkstationModel> {
    return this.http.put<WorkstationModel>(this.apiurl + 'workstation/', form)
  }

  endWorkflow(type) {
    const url = this.apiurl + 'workflow';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        type,
      },
    };

    return this.http.delete(url, options);
  }

  endWorkflowByAdmin(user_id, type) {
    const url = this.apiurl + 'workstation/workflow/end-by-admin';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        user_id: user_id,
        type: type,
      },
    };

    return this.http.delete(url, options);
  }
}

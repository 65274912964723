import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProblemsService} from "../../../services/problems/problems.service";
import {ParcelsService} from "../../../services/parcels/parcels.service";
import {ToastsService} from "../../../services/toasts.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {WorkstationService} from "../../../services/workstation/workstation.service";
import {ProblematicSectorService} from "../../../services/problematic-sector/problematic-sector.service";
import {NgForm} from "@angular/forms";
@Component({
  selector: 'app-problematic-sector-list',
  templateUrl: './problematic-sector-list.component.html',
  styleUrls: ['./problematic-sector-list.component.scss']
})
export class ProblematicSectorListComponent implements OnInit {
  id = null;
  statusTypes=[];
  problem_type = {
    id: null,
    name: null,
  };

  constructor(
    private problemsService: ProblemsService,
    private workstationService: WorkstationService,
    private route: ActivatedRoute,
    private router: Router,
    private parcelsServices: ParcelsService,
    private toastsService: ToastsService,
    private problematicSectorService: ProblematicSectorService
  ) {
  }

  warehouseId = 1;
  warehouses: any = [];
  problematicSectors: any [];

  getStatusTypes(){
    this.parcelsServices.getStatusTypes().subscribe(
      (response: [])=>{
        this.statusTypes=response;
      }
    );
  }

  dtOptions: DataTables.Settings = {};

  problematicSectorFormData = {
    warehouseId: 1
  }
  ngOnInit(): void {

    try {
      const id = this.route.snapshot.paramMap.get('id');
      if (id != undefined)
        this.id = id;
    } catch (error) {
      this.id = null;
    }
    this.getStatusTypes();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };

    this.listProblematicSectors(this.warehouseId);

    this.workstationService.listWarehouses().subscribe(
      (response) => {
        this.warehouses = response;
      },
      (error) => {
        console.error('Błąd przy pobieraniu magazynów:', error);
      }
    );
  }

  listProblematicSectors(workstationId) {
    this.problematicSectorService.list(this.warehouseId).subscribe(
      (response: any) => {
        this.problematicSectors = response
      }
    );
  }

  submitProblematicSectorForm(form: NgForm) {
    this.warehouseId = this.problematicSectorFormData.warehouseId
    this.listProblematicSectors(this.problematicSectorFormData.warehouseId)
  }

  async delete(problematicSectorId){
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.problematicSectorService.deleteProblematicSector(problematicSectorId).subscribe(
      (response: any)=>{
        window.location.reload();
        this.toastsService.showMessage('Usunięto pomyślnie!', 's');
      }
    );
  }

}

<div class="mobile-overflow">
  <form #workflowForm="ngForm"  (ngSubmit)="submitWorkstationWorkflowForm(workflowForm)">
    <div class="uk-grid">
      <div class=" uk-width-1-4@m">
        <label>{{'employee' | translate}}</label>
        <select [(ngModel)]="workstationWorkflowFormData.userId" class="uk-select" name="userId">
          <option value="" disabled selected>Wybierz e-mail</option>
          <option *ngFor="let employee of employees" [value]="employee.id">
            {{ employee.email }}
          </option>
        </select>
      </div>

      <div class="uk-width-1-4@m">
        <label>{{'workstation_type' | translate}}</label>
        <select class="uk-select"  id="workstation_type" name="workstation_type" [(ngModel)]="workstationWorkflowFormData.typeId">
          <option *ngFor="let workstation_type of workstation_types" [value]="workstation_type.id">
            {{ workstation_type.name }} ({{workstation_type.code}})
          </option>
        </select>
      </div>

    </div>
    <div class="uk-margin">
      <button type="submit" class="uk-button uk-button-primary">{{'filter' | translate}}</button>
    </div>
  </form>

  <hr>

  <div *ngIf="workflowData.length > 0">

    <table class="uk-table uk-table-divider uk-table-hover uk-width-1-1" datatable [dtOptions]="dtOptions">
      <thead>
      <tr>
        <th>ID</th>
        <th>Stanowisko</th>
        <th>Początek</th>
        <th>Koniec</th>
        <th>Zakończenie</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let workflow of workflowData">
        <td>{{ workflow.id }}</td>
        <td>{{ workflow.code }}</td>
        <td>{{ workflow.start_at }}</td>
        <td>{{ workflow.end_at }}</td>
        <td>{{ workflow.end_type }}</td>

      </tr>
      </tbody>
    </table>
  </div>
</div>


